import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        path: "/",
        redirect: "/home",
        component: () => import("@/view/Main"),
        children: [
           
            {
                path: "/",
                name: "ged",
                component: () => import("@/view/components/Ged"),

            },
            // {
            //     path: "/",
            //     name: "Servicos",
            //     component: () => import("@/view/components/Servicos"),

            // },
            // {
            //     path: "/",
            //     name: "Solucoes",
            //     component: () => import("@/view/components/Solucoes"),

            // },

            {
                path: "/login",
                name: "Login",
                component: () => import("@/view/components/Login"),

            },

            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/view/components/Dashboard"),

            },




               ////EMPRESA////
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },

                ////FIM EMPRESA///
                //////FILIAL/////
                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/Permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },


                //perfil
            {
                path: "/perfil",
                name: "perfil",
                component: () =>
                    import("@/view/components/config/perfil/listaPerfil.vue")
            },

            {
                path: "/createPerfil",
                name: "createPerfil",
                component: () =>
                    import("@/view/components/config/perfil/createPerfil.vue")
            },
          //fim perfil

          {
            path: "/createFuncionario",
            name: "createFuncionario",
            component: () => import("@/view/components/rh/createFuncionario.vue")
        },
        {
            path: "/dadosUsuario",
            name: "dadosUsuario",
            component: () => import("@/view/components/rh/dadosUsuario.vue")
        },

        {
            path: "/gerenFuncionarios",
            name: "gerenFuncionarios",
            component: () => import("@/view/components/rh/gerenFuncionario.vue")
        },


             //cartorio
             {
                path: "/cartorio",
                name: "cartorio",
                component: () =>
                    import("@/view/components/cartorio/listaCartorio.vue")
            },

            {
                path: "/createCartorio",
                name: "createCartorio",
                component: () =>
                    import("@/view/components/cartorio/createCartorio.vue")
            },
          //fim cartorio
             //cartegoria
             {
                path: "/categoria-certidao",
                name: "categoria-certidao",
                component: () =>
                    import("@/view/components/configCertidoes/categoriaCertidao/listaCategoriaCertidao.vue")
            },

            {
                path: "/createCategoriaCertidao",
                name: "createCategoriaCertidao",
                component: () =>
                    import("@/view/components/configCertidoes/categoriaCertidao/createCategoriaCertidao.vue")
            },
          //fim cartegoria
            
               //cartegoria
               {
                path: "/certidao",
                name: "certidao",
                component: () =>
                    import("@/view/components/configCertidoes/certidao/listaCertidao.vue")
            },

            {
                path: "/createCertidao",
                name: "createCertidao",
                component: () =>
                    import("@/view/components/configCertidoes/certidao/createCertidao.vue")
            },
          //fim cartegoria

               //pedidos

          {
            path: "/pedidos",
            name: "pedidos.index",
            component: () =>
                import("@/view/components/configCertidoes/pedidos/listaPedidos.vue")
        },
          //fim pedidos

          {
            path: "/certidoes",
            name: "certidoes",
            component: () =>
                import("@/view/components/layout/Certidoes.vue")
        },
        {
            path: "/certidao/:nome",
            name: "sitecertidao",
            component: () =>
                import("@/view/components/layout/Certidao.vue")
        },
        {
            path: "/carrinho",
            name: "carrinho.index",
            component: () =>
                import("@/view/components/layout/Carrinho.vue")
        },
        {
            path: "/meus-pedidos",
            name: "meuspedidos.index",
            component: () =>
                import("@/view/components/layout/MeusPedidos.vue")
        },
        {
            path: "/meus-dados",
            name: "meusdados.update",
            component: () =>
                import("@/view/components/layout/MeusDados.vue")
        },

          //site
        ]
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router; // Certifique-se de exportar o router