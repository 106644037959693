
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CARTORIO = "setListCartorio";
export const SET_LISTA_CARTORIO_REV = "setListCartoriosRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CARTORIO = "setCartorio"



const actions = {
    //protocolos
    async create_cartorio(context, value) {

        await ApiService.post('cartorio', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_cartorio(context, value) {

        await ApiService.put('cartorio/' + value.cartorio.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_cartorio(context) {
        await ApiService.get('cartorio')
            .then(response => {
                context.commit(SET_LISTA_CARTORIO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

    set_cartorio(context, item) {
        context.commit(SET_CARTORIO, item)
    },

    async delete_cartorio(context, value) {


        await ApiService.delete("cartorio/" + value.id)
            .then((response) =>{
        context.commit(SET_LISTA_CARTORIO_REV, value)

                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            }
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


    },


};
const getters = {};

const mutations = {

    [SET_LISTA_CARTORIO](state, value) {
        state.lista_cartorio = value;
    },
  
    [SET_LISTA_CARTORIO_REV](state, value) {
        state.lista_cartorio = state.lista_cartorio.filter(
            (cartorio) => cartorio.id !== value.id
        );
    },
    [SET_CARTORIO](state, value) {
        state.cartorio = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_CARTORIO](state, value) {
        state.lista_cartorio = value;
    },


};

const state = {
    lista_cartorio: [],
    cartorio: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
