import ApiService from "@/core/services/api.service";

export const SET_LIST_PERMISSOES = "setListPermissoes";
export const SET_LIST_ROLES = "setListRoles";
export const SET_ROLE = "setRole";
export const SET_USER_ROLES = "setUserRole";
export const SET_LIST_ROLES_PERMISSOES = "setListRolesPermissoes"


const actions = {
    async all_permissions_regra(context, value) {
        await ApiService.get('permissoes/roles/' + value + '/permission')
            .then(response => context.commit(SET_LIST_ROLES_PERMISSOES, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    async all_permissions(context) {
        await ApiService.get('permissoes/list_permissions')
            .then(response => context.commit(SET_LIST_PERMISSOES, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    async all_roles(context) {
        await ApiService.get('permissoes/list_roles')
            .then(response => context.commit(SET_LIST_ROLES, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response && error.response.data? error.response.data :error.response

            }))
    },

    async read_role(context, value) {
        await ApiService.get('permissoes/read_role/' + value)
            .then(response => context.commit(SET_ROLE, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    async read_user_roles(context, value) {
        await ApiService.get('permissoes/read_user_roles/' + value)
            .then(response => context.commit(SET_USER_ROLES, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response
            }))
    },

    async create_permission(context, value) {
        let message = await ApiService.post('permissoes/create_permission', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async create_role(context, value) {
        let message = await ApiService.post('permissoes/create_role', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async update_role(context, value) {
        let message = await ApiService.put('permissoes/update_role/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async delete_role(context, value) {
        let message = await ApiService.get('permissoes/delete_role/' + value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async vincular_permisao_regra(context, value) {
        let message = await ApiService.post('permissoes/role_permission', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async vincular_permisao_usuario(context, value) {
        let message = await ApiService.post('permissoes/give_permission', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async vincular_regra_usuario(context, value) {
        let message = await ApiService.post('permissoes/assign_role', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async desvincular_permissao_regra(context, value) {
        let message = await ApiService.post('permissoes/revoke_role_permission', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
};

const getters = {};

const mutations = {
    [SET_LIST_PERMISSOES](state, value) {
        state.lista_permissons = value
    },
    [SET_LIST_ROLES](state, value) {
        state.lista_roles = value
    },
    [SET_LIST_ROLES_PERMISSOES](state, value) {
        state.lista_roles_permissoes = value
    },
    [SET_ROLE](state, value) {
        state.role = value
    },
    [SET_USER_ROLES](state, value) {
        state.user_roles = value
    },
};

const state = {
    lista_permissons: [],
    lista_roles: [],
    lista_roles_permissoes: [],
    role: {},
    user_roles: []
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};