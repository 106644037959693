
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CERTIDAO= "setListCertidao";
export const SET_LISTA_CERTIDAO_REV = "setListCertidaosRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CERTIDAO= "setCertidao"



const actions = {
    //protocolos
    async create_certidao(context, value) {

        await ApiService.post('certidao', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_certidao(context, value) {

        await ApiService.put('certidao/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_certidao(context) {
        await ApiService.get('certidao')
            .then(response => {
                context.commit(SET_LISTA_CERTIDAO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

    set_certidao(context, item) {
        context.commit(SET_CERTIDAO, item)
    },

    async delete_certidao(context, value) {


        await ApiService.delete("certidao/" + value.id)
            .then((response) =>{
        context.commit(SET_LISTA_CERTIDAO_REV, value)

                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            }
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


    },


};
const getters = {};

const mutations = {

    [SET_LISTA_CERTIDAO](state, value) {
        state.lista_certidao = value;
    },
  
    [SET_LISTA_CERTIDAO_REV](state, value) {
        console.log('teste')
        state.lista_certidao = state.lista_certidao.filter(
            (certidao ) => certidao.id !== value.id
        );
    },
    [SET_CERTIDAO](state, value) {
        state.cartorio = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_CERTIDAO](state, value) {
        state.lista_certidao = value;
    },


};

const state = {
    lista_certidao: [],
    cartorio: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
