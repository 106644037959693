
import ApiService from "@/core/services/api.service";


export const SET_LISTA_PEDIDOS= "setListPedidos";
export const SET_LISTA_PEDIDOS_REV = "setListPedidosRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_PEDIDOS= "setPedidos"
export const SET_LISTA_PEDIDOS_USER= "setListPedidosUser"
export const SET_LISTA_PEDIDOS_CARRINHO= "setListPedidosUserCarrinho"
export const SET_LISTA_PEDIDOS_USER_REV = 'setListPedidosUserRev'




const actions = {
    //protocolos
    async create_pedidos(context, value) {

        await ApiService.post('pedidos', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_pedidos(context, value) {

        await ApiService.put('certidao/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_pedidos(context) {
        await ApiService.get('pedidos')
            .then(response => {
                context.commit(SET_LISTA_PEDIDOS, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

    async listar_pedidos_user(context,params) {
        await ApiService.get('pedidos/user/'+params)
            .then(response => {
                context.commit(SET_LISTA_PEDIDOS_USER, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

    async listar_meus_pedidos(context,params) {
        await ApiService.get('pedidos/meusPedidos/'+params)
            .then(response => {
                context.commit(SET_LISTA_PEDIDOS_USER, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },
    

    async listar_pedidos_carrinho(context,params) {
        await ApiService.get('pedidos/user-carrinho/'+params)
            .then(response => {
                context.commit(SET_LISTA_PEDIDOS_CARRINHO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

 
    async update_endereco_pedido(context, params) {

        await ApiService.post('pedidos/endereco/'+params.id,params)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },


    

    set_pedidos(context, item) {
        context.commit(SET_PEDIDOS, item)
    },

    async delete_pedidos(context, value) {


        await ApiService.delete("pedidos/" + value.id)
            .then((response) =>{
        context.commit(SET_LISTA_PEDIDOS_REV, value)

                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            }
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );
    },

    async comprar(context, params){
        await ApiService.post('pedidos/comprar',{pedidos:params})
        .then(response => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        }))
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },

    async update_status_pedidos(context, params){
        await ApiService.put('pedidos/update-status-pedidos',params)
        .then(response => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        }))
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },
    async update_status_item_pedido(context, params){
        await ApiService.put('pedidos/update-status-item-pedidos',params)
        .then(response => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        }))
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },

    
    async update_status_conta(context, params){
        await ApiService.put('pedidos/update-status-conta',params)
        .then(response => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        }))
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },

    async delete_pedidos_user(context, params){
        await ApiService.delete('pedidos/'+params.id)
        .then(response =>
            
            {
                context.commit( SET_LISTA_PEDIDOS_USER_REV,params)
                context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        })})
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },

    


    

    
};
const getters = {};

const mutations = {

    [SET_LISTA_PEDIDOS](state, value) {
        state.lista_pedidos = value;
    },
    
    [SET_LISTA_PEDIDOS_USER](state, value) {
        state.lista_pedidos_user = value;
    },
    [SET_LISTA_PEDIDOS_CARRINHO](state, value) {
        state.lista_pedidos_carrinho = value;
    },
    [SET_LISTA_PEDIDOS_USER_REV](state, value) {
        state.lista_pedidos_user = state.lista_pedidos_user.filter(
            (pedido ) => pedido.id !== value.id
        );

        state.lista_pedidos_carrinho =  state.lista_pedidos_user.lenght
    },

    
  
    [SET_LISTA_PEDIDOS_REV](state, value) {
        console.log('teste')
        state.lista_pedidos = state.lista_pedidos.filter(
            (certidao ) => certidao.id !== value.id
        );
    },
    [SET_PEDIDOS](state, value) {
        state.pedidos = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_PEDIDOS](state, value) {
        state.lista_pedidos = value;
    },




};

const state = {
    lista_pedidos_user:[],
    lista_pedidos: [],
    lista_pedidos_carrinho:0,
    pedidos: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
