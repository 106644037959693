
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CERTIDAO= "setListCertidaoSite";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CERTIDAO= "setCertidaoSite"
export const SET_CERT_CID_EST = "setCertCidEst"
export const SET_LISTA_PERFIL ="setListPerfil"

const actions = {
    //protocolos

 
    async listar_certidoes(context) {
        await ApiService.get('site/certidoes')
            .then(response => {
                context.commit(SET_LISTA_CERTIDAO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },
    async listar_certidao(context,params) {
        await ApiService.post('site/certidao',{nome:params})
            .then(response => {
                context.commit(SET_CERTIDAO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },
    async listar_cert_cid_est(context,params) {
        await ApiService.get('site/cert-cid-Est/'+params)
            .then(response => {
                context.commit(SET_CERT_CID_EST, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

   
    async listar_perfil(context) {
        await ApiService.get("site/perfils")
          .then((response) => context.commit(SET_LISTA_PERFIL, response.data))
          .catch((error) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "error",
              message: error.response.data,
            })
          );
      },

    set_certidao(context, item) {
        context.commit(SET_CERTIDAO, item)
    },

    async create_usuario(context, value) {
        let message = await ApiService.post('site/criar-usuario', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },


};
const getters = {};

const mutations = {

    [SET_LISTA_CERTIDAO](state, value) {
        state.lista_certidao = value;
    },
    [SET_LISTA_PERFIL](state, value) {
        state.lista_perfil = value;
    },
    
 
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_CERTIDAO](state, value) {
        state.certidao = value;
    },
    [SET_CERT_CID_EST](state, value) {
        state.lista_certcidest = value;
    },
    


};

const state = {
    lista_certidao: [],
    lista_perfil:[],
    certidao: {},
    mensagem_alert: "",
    lista_certcidest:[]

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
