
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CATEGORIA_CERTIDAO= "setListCategoriaCertidao";
export const SET_LISTA_CATEGORIA_CERTIDAO_REV = "setListCategoriaCertidaosRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CATEGORIA_CERTIDAO= "setCategoriaCertidao"



const actions = {
    //protocolos
    async create_categoria_certidao(context, value) {

        await ApiService.post('categoria-certidao', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_categoria_certidao(context, value) {

        await ApiService.put('categoria-certidao/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_categoria_certidao(context) {
        await ApiService.get('categoria-certidao')
            .then(response => {
                context.commit(SET_LISTA_CATEGORIA_CERTIDAO, response.data)
            })
            .catch((error) => {
                console.log(error)
                // context.commit(SET_MESSAGE_ALERT, {
                //     tipo: 'error',
                //     message: error.response 
                // })
            })
    },

    set_categoria_certidao(context, item) {
        context.commit(SET_CATEGORIA_CERTIDAO, item)
    },

    async delete_categoria_certidao(context, value) {


        await ApiService.delete("categoria-certidao/" + value.id)
            .then((response) =>{
        context.commit(SET_LISTA_CATEGORIA_CERTIDAO_REV, value)

                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            }
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


    },


};
const getters = {};

const mutations = {

    [SET_LISTA_CATEGORIA_CERTIDAO](state, value) {
        state.lista_categoria_certidao = value;
    },
  
    [SET_LISTA_CATEGORIA_CERTIDAO_REV](state, value) {
        console.log('teste')
        state.lista_categoria_certidao = state.lista_categoria_certidao.filter(
            (categoria_certidao ) => categoria_certidao.id !== value.id
        );
    },
    [SET_CATEGORIA_CERTIDAO](state, value) {
        state.cartorio = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_CATEGORIA_CERTIDAO](state, value) {
        state.lista_categoria_certidao = value;
    },


};

const state = {
    lista_categoria_certidao: [],
    cartorio: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
