<template>
  
  <v-app>
    <vue3-confirm-dialog></vue3-confirm-dialog>
    
    <v-main>
      <router-view></router-view>

    </v-main>
  </v-app>
</template>

<script>

export default {

  name: 'App',

  

  data: () => ({
    //
  }),

}
</script>
