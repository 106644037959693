import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_EMPRESAS = "setListEmpresa";
export const SET_LISTA_FILIALS = "setListFilial";
export const SET_LISTA_PERFIL = "setListPerfil";
export const SET_LISTA_FILIALS_REV = "setListFilialRev";
export const SET_LISTA_EMPRESA_REV = "setListEmpresaRev";
export const SET_LISTA_PERFIL_REV = "setListPerfilRev"

const actions = {
  async create_filial(context, value) {
    ApiService.setHeader();
    await ApiService.post("filial", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_filial(context, value) {
    ApiService.setHeader();
    await ApiService.put("filial/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },




  async create_empresa(context, value) {
    ApiService.setHeader();
    await ApiService.post("empresa/", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_empresa(context, value) {
    ApiService.setHeader();
    await ApiService.put("empresa/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },




  async create_perfil(context, value) {
    ApiService.setHeader();
    await ApiService.post("perfil", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_perfil(context, value) {
    ApiService.setHeader();

    await ApiService.put("perfil/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async bloquear_perfil(context, value) {
    ApiService.setHeader();

    await ApiService.delete("perfil/" + value.id)
      .then((response) =>{
        context.commit(SET_LISTA_PERFIL_REV,value)
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      }
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async listar_empresas(context) {
    ApiService.setHeader();
    await ApiService.get("empresa")
      .then((response) => context.commit(SET_LISTA_EMPRESAS, response.data))
      .catch((error) => {
          context.commit("SET_MESSAGE_ALERT", {
            tipo: "error",
            message: error.response.data,
          })
        }

      );

  },
  async listar_filial(context) {
    ApiService.setHeader();
    await ApiService.get("filial")
      .then((response) => context.commit(SET_LISTA_FILIALS, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async delete_filial(context, value) {


    await ApiService.delete("filial/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );

    context.commit(SET_LISTA_FILIALS_REV, value)

  },


  async delete_empresa(context, value) {


    await ApiService.delete("empresa/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );

    context.commit(SET_LISTA_EMPRESA_REV, value)

  },




  async listar_perfil(context) {
    ApiService.setHeader();
    await ApiService.get("perfil")
      .then((response) => context.commit(SET_LISTA_PERFIL, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },


  async MudarPreloader(context, value) {

    context.commit("SET_PRELOADER", value);

  },
  atualizar(context, value) {
    context.commit("SET_CAMPOS", value);
  },
  guardarDados(context, value) {
    context.commit("SET_DADOS_GUARDADOS", value);
  },
  aluno_id(context, value) {
    context.commit("SET_DADOS_ALUNO", value);
  },
  semestre_id(context, value) {
    context.commit("SET_DADOS_SEMESTRE_ID", value);
  },
};

const getters = {};

const mutations = {
  [SET_LISTA_EMPRESAS](state, value) {
    state.lista_empresas = value;
  },
  [SET_LISTA_FILIALS](state, value) {
    state.lista_filials = value;
  },




  [SET_LISTA_PERFIL](state, value) {
    state.lista_perfil = value;
  },
  SET_PRELOADER(state, value) {

    state.isLoading = value;
  },
  SET_CAMPOS(state, value) {
    state.lista_campos = value;
  },
  SET_DADOS_GUARDADOS(state, value) {
    state.lista_dados_guardados = value;
  },
  SET_MESSAGE_ALERT(state, value) {
    console.log(value)
    let customMessage;
    if (typeof value.message === "object") {

        customMessage = value.message.msg;
    }
    state.mensagem_alert = {
        tipo: value.tipo,
        message: customMessage ? customMessage : value.message,
    };
},
  [SET_LISTA_FILIALS_REV](state, value) {
    state.lista_filials = state.lista_filials.filter(
      (filiais) => filiais.id !== value.id
    );
  },
  [SET_LISTA_EMPRESA_REV](state, value) {
    state.lista_empresas = state.lista_empresas.filter(
      (empresa) => empresa.id !== value.id
    );
  },
  [SET_LISTA_PERFIL_REV](state, value) {
    state.lista_perfil = state.lista_perfil.filter(
      (perfil) => perfil.id !== value.id
    );
  }

};

const state = {
  lista_empresas: [],
  lista_filials: [],

  isLoading: false,
  lista_campos: "",
  mensagem_alert: "",
  lista_dados_guardados: [],

  lista_perfil: [],
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
