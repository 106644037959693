import { createApp } from 'vue'
import App from './App.vue'
import { loadFonts } from './plugins/webfontloader'
import vuetify from '@/core/plugins/vuetify'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import 'vue3-treeselect/dist/vue3-treeselect.css'
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";


import VueMask from '@devindex/vue-mask';
// import BootstrapVue from 'bootstrap-vue';
// import VueSimpleAlert from "vue-simple-alert";
import router from "@/core/router/router";
import { store } from "@/core/store";
import ApiService from "@/core/services/api.service";
import SiteService from "@/core/services/site.service";

import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import 'vue3-confirm-dialog/style';
import VueSimpleAlert from 'vue3-simple-alert'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

loadFonts()
const app = createApp(App);

app.use(router);
app.use(store);
app.use(Vue3ConfirmDialog);
app.use(vuetify);
app.use(VueSimpleAlert);
app.use(VueMask); 
app.component('QuillEditor', QuillEditor)

app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default)
ApiService.init();
SiteService.init();
window.onerror = function(message, source, lineno, colno, error) {
    // Registre o erro em um serviço de rastreamento de erros ou arquivo de log
    console.error('Erro não exibido na tela:', error);
  
    // Retorne true para evitar que o erro seja exibido no console do navegador
    return false;
  };

// app.use(BootstrapVue);
// app.use(IconsPlugin);




app.mount('#app');
