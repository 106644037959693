import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'; // Importe o CSS dos ícones MDI

// import { VDatePicker } from 'vuetify/labs/VDatePicker'

  import {
    VDataTable,
    VDataTableServer,
    VDataTableVirtual,
  } from "vuetify/labs/VDataTable";

const vuetify =   
createVuetify({
    ...components,

   components: {
       VDataTable,
       VDataTableServer,
       VDataTableVirtual,
      //  VDatePicker
   },
   directives,

 })


export default  vuetify