
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CIDADES = "setListCidades";
export const SET_LISTA_ESTADOS = "setListEstados";
export const SET_LISTA_CIDADES2 = "setListCidades2";
export const SET_LISTA_ESTADOS2 = "setListEstados2";
export const SET_LISTA_PAISES = "setListPaises";



export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async listar_estados(context) {
        await ApiService.get(`estado`)

            .then(response => {
                context.commit(SET_LISTA_ESTADOS, response.data)
                context.commit(SET_LISTA_ESTADOS2, response.data)

            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    
    async listar_paises(context) {
        await ApiService.get(`pais`)

            .then(response => {
                context.commit(SET_LISTA_PAISES, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    

    async listar_cidades(context,value) {
        await ApiService.get(`cidade/pesquisa/${value}`)
            .then(response => {
                context.commit(SET_LISTA_CIDADES, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_todas_cidades(context) {
        await ApiService.get(`cidade/pesquisa`)
            .then(response => {
                context.commit(SET_LISTA_CIDADES, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },




    // para parte de certidao 
    async listar_cidades2(context,value) {
        await ApiService.get(`cidade/pesquisa/${value}`)
            .then(response => {
                context.commit(SET_LISTA_CIDADES2, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
 
    

    
 


};
const getters = {};

const mutations = {
    [SET_LISTA_CIDADES](state, value) {
        state.lista_cidades =  value.map(cidade => ({id: cidade.id, label: cidade.nome,isDisabled: false,estado_id:cidade.estado_id}))

    },
    [SET_LISTA_ESTADOS](state, value) {
        state.lista_estados =  value.map(estado => ({id: estado.id, label: estado.nome,uf:estado.uf}))
    },
    [SET_LISTA_CIDADES2](state, value) {
        state.lista_cidades2 =  value.map(cidade => ({id: cidade.id, label: cidade.nome,estado_id:cidade.estado_id}))

    },
    [SET_LISTA_ESTADOS2](state, value) {
        state.lista_estados2 =  value.map(estado => ({id: estado.id, label: estado.nome,uf:estado.uf}))
    },
    [SET_LISTA_PAISES](state, value) {
        state.lista_paises =  value.map(pais => ({id: pais.id, label: pais.nome,    }))
    },
    

    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_cidades: [],
    lista_estados: [],
    lista_cidades2: [],
    lista_estados2: [],
    lista_paises:  [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
