const ID_USUARIO_KEY = "key_usu";
const ID_USUARIO_HASH_KEY = "Hash_usu";


export const getUsuario = () => {
    return JSON.parse(window.localStorage.getItem(ID_USUARIO_KEY));
};

export const saveUsuario = Usuario => {
    window.localStorage.setItem(ID_USUARIO_KEY, JSON.stringify(Usuario));
};

export const destroyUsuario = () => {
    window.localStorage.removeItem(ID_USUARIO_KEY);
};


export const saveUsuarioHash  = UsuarioHash => {
    window.localStorage.setItem(ID_USUARIO_HASH_KEY, UsuarioHash);
};
export const getUsuarioHash  = () => {
    return window.localStorage.getItem(ID_USUARIO_HASH_KEY);
};


export default { getUsuario, saveUsuario, destroyUsuario,getUsuarioHash,saveUsuarioHash };