import { createApp } from 'vue'
import App from '../../App.vue'
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import FilialService from "@/core/services/filial.service";
import { LOGOUT } from "@/core/store/auth.module";
import Swal from "sweetalert2";
import { store } from "@/core/store";

import router from "@/core/router/router";

let isRefreshing = false;
const app = createApp(App);

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
// app.use(store)
// app.use(router)

app.use(VueAxios, axios);
app.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

app.axios.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
      config.headers["filial"] = 1;
      return config;
    });

    // app.axios.interceptors.response.use(

    //   (response) => {
     
    //   // console.log('teste')

    //     let tokenTTL = JwtService.getTokenTimingLeft();
    //     if (tokenTTL && tokenTTL / (1000 * 60) < 30 && isRefreshing === false) {
    //       isRefreshing = true;
    //       axios
    //         .create({
    //           baseURL: process.env.VUE_APP_API_URL,
    //           headers: { Authorization: `Bearer ${JwtService.getToken()}` },
    //         })
    //         .post(`auth/refresh`)
    //         .then(({ data }) => JwtService.saveToken(data.access_token))
    //         .finally(() => (isRefreshing = false));
    //     }


    //     return response;
    //   },
    //   (error) => {
    //     console.log(error.response)
  
    //     if (
    //       error.response.status === "401" &&

    //       error.response !== "unauthorized"
    //     ) {
    //       store.dispatch(LOGOUT);
    //       router.push({ name: "Login" });
    //       Swal.fire({
    //         title: "Realize login novamente!",
    //         icon: "error",
    //       });
    //     }else{
    //       Swal.fire({
    //         title: "Error",
    //         icon: "error",
    //         text: typeof error.response.data === "object" ? error.response.data.message : error.response.data
    //       });
    //     }
    //     return Promise.reject(error);
    //   }


    // );
    // store.dispatch(LOGOUT);
    
    // router.push({ name: "Login" });
    // Swal.fire({
    //   title: "Realize login novamente!",
    //   icon: "error",
    // });



  },

  setHeader() {
    app.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    app.axios.defaults.headers.common["filial"] = 1;
  },

  setHeaderFilial() {
    app.axios.defaults.headers.common["filial"] = 1;
  },

  query(resource, params) {
    return app.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  get(resource, params) {
    store.dispatch("configEmpresa/MudarPreloader", true)

    return app.axios.get(`${resource}`, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    })
    .finally(() => store.dispatch("configEmpresa/MudarPreloader", false));
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config) {
    return app.axios.post(resource, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return app.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return app.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return app.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  getArquivo(rota, dados) {
    return axios
      .post(rota, dados, {
        responseType: "arraybuffer",
        Authorization: `Bearer ${JwtService.getToken()}`,
      })
      .then((response) => {
        console.log(response);

        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        }),
          url = window.URL.createObjectURL(blob);

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      });
  },
};

export default ApiService;
