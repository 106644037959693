import { createStore } from 'vuex'
import site from "@/core/store/vuex/modulos/site"
import auth from "@/core/store/auth.module"
import sistema from "./vuex/modulos/sistema";
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import permissoes from "./vuex/modulos/permissoes"
import breadcrumbs from "./breadcrumbs.module";
import cartorio from "./vuex/modulos/cartorio"
import endereco from "./vuex/modulos/endereco/endereco"
import categoriaCertidao from "./vuex/modulos/categoriaCertidao"
import certidao from "./vuex/modulos/certidao"
import pedidos from './vuex/modulos/pedidos';



export const store = createStore({
    modules: {
        site,
        auth,
        sistema,
        configEmpresa,
        configUsuarios,
        permissoes,
        breadcrumbs,
        cartorio,
        endereco,
        categoriaCertidao,
        certidao,
        pedidos

    }
})
